.wa-widget {
  margin: 0;
  position: fixed;
  bottom: 0;
  right: 0;
}

.wa-widget:last-child {
  margin-right: 0;
}

.wa-widget.expanded {
  border-radius: 1rem;
}

.wa-widget .wa-widget-content {
  width: 360px;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all .25s linear;
  position: absolute;
  bottom: 6rem;
  right: 0;
}

.wa-widget .wa-widget-content.expanded {
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.wa-widget-content, .wa-widget-toggle {
  margin: 1rem;
  box-shadow: 0 0 10px 5px #0000001a;
}

.wa-widget-toggle {
  height: 2rem;
  width: 2rem;
  text-align: center;
  cursor: default;
  background-color: #fff;
  border-radius: 9rem;
  align-items: center;
  padding: 1rem;
  display: inline-flex;
  position: relative;
}

.wa-widget-toggle:before {
  content: "";
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 10px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.wa-widget-content {
  border-radius: .75rem;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  overflow: hidden;
}

.chat-header {
  color: #fff;
  background-color: #095e54;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.chat-header .chat-admin-picture {
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  position: relative;
}

.chat-header .chat-admin-picture img {
  width: 100%;
  height: 100%;
}

.chat-header .chat-admin-picture:before {
  content: "";
  height: 7px;
  width: 7px;
  background-color: #4fce5d;
  border: 2px solid #095e54;
  border-radius: 7px;
  position: absolute;
  bottom: 2.33333px;
  right: 2.33333px;
}

.chat-header h4, .chat-header p {
  margin: .2rem;
}

.chat-content {
  background-color: #d1ecd4;
  padding: 1rem;
}

.chat-content .chat-admin-picture {
  height: 2rem;
  width: 2rem;
  margin-right: .5rem;
}

.chat-item {
  align-items: flex-end;
  display: inline-flex;
}

.chat-item p {
  background-color: #fff;
  border-radius: 1rem;
  margin: 0 2rem .5rem 0;
  padding: 1rem;
}

.chat-item p:first-child {
  border-top-left-radius: 0;
}

.chat-form {
  background-color: #fff;
  padding: .25rem;
}

.chat-form input, .chat-form textarea {
  width: 100%;
  background-color: inherit;
  resize: none;
  margin-bottom: .25rem;
  padding: .75rem 1rem;
  font-family: inherit;
  transition: all .25s linear;
}

.chat-form input, .chat-form input:focus, .chat-form textarea {
  border: 1px solid #0000;
  border-radius: .5rem;
  outline: 0;
}

.chat-form .chat-send {
  width: 100%;
  color: #fff;
  cursor: pointer;
  background-color: #4fce5d;
  border: 0;
  border-radius: .5rem;
  outline: 0;
  padding: .75rem;
  font-size: 1.1rem;
  font-weight: 700;
}

.chat-form .required {
  background-color: #ff00001a;
  border: 1px solid #ff000040;
}

@media (max-width: 460px) {
  .wa-widget {
    width: 100%;
  }

  .wa-widget-toggle {
    float: right;
  }

  .wa-widget-content {
    width: auto;
    left: 1rem;
    right: 1rem;
  }
}

/*# sourceMappingURL=whatsAppWidget.efb5f22d.css.map */
